<template>
  <div>
    <div id="display-filters" class="wrapper mbl-lptp-only">
      <button>
        <span
          class="subhead-xs bold"
          v-if="showFiltersTitle"
          v-text="showFiltersTitle"
        />
        <span class="icon-filters">
          <svg
            viewBox="0 0 38 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29.6 7.1c.3-.4.6-.9.7-1.5h6.2c.6 0 1-.4 1-1s-.4-1-1-1h-6.2c-.2-.9-.8-1.8-1.6-2.4-1.9-1.4-4.5-.9-5.8.9-.3.4-.5.9-.7 1.5H1.5c-.6 0-1 .4-1 1s.4 1 1 1h20.7c.2.9.8 1.8 1.6 2.4 1.8 1.4 4.5 1 5.8-.9ZM25 6.4c-.5-.3-.8-.8-.9-1.4-.1-.6 0-1.1.4-1.6.7-1 2.1-1.2 3-.5.5.3.8.8.9 1.4v.3c0 .5-.1.9-.4 1.3-.7 1-2.1 1.2-3 .5ZM14.8 18.5c.3-.4.6-.9.7-1.5h21c.6 0 1-.4 1-1s-.4-1-1-1h-21c-.2-.9-.8-1.8-1.6-2.4-1.9-1.4-4.5-.9-5.8.9-.3.4-.5.9-.7 1.5H1.5c-.6 0-1 .4-1 1s.4 1 1 1h5.9c.2.9.8 1.8 1.6 2.4 1.8 1.3 4.4.9 5.8-.9Zm-4.6-.8c-.5-.3-.8-.8-.9-1.4-.1-.6 0-1.1.4-1.6.7-1 2.1-1.2 3-.5.5.3.8.8.9 1.4v.3c0 .5-.1.9-.4 1.3-.7 1-2.1 1.2-3 .5Zm15.5 12.1c.3-.4.6-.9.7-1.5h10.1c.6 0 1-.4 1-1s-.4-1-1-1H26.4c-.2-.9-.8-1.8-1.6-2.4-.9-.7-2-.9-3.1-.8-1.1.2-2.1.8-2.7 1.7-.3.5-.5.9-.7 1.5H1.5c-.6 0-1 .4-1 1s.4 1 1 1h16.9c.2.9.8 1.8 1.6 2.4 1.8 1.3 4.4.9 5.7-.9Zm-4.6-.7c-.5-.4-.8-.9-.9-1.5v-.4c0-.4.2-.7.4-1 .3-.5.8-.8 1.4-.9.6-.1 1.1.1 1.6.4.5.3.8.8.9 1.4v.3c0 .5-.1.9-.4 1.3-.7.9-2 1.1-3 .4Z"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </button>
    </div>
    <div class="wrapper filter-wrapper">
      <div id="reset-dsktp" class="lptp-lg-only" v-if="resetBtn">
        <div class="wrapper">
          <button
            class="btn no-bg"
            v-html="resetBtn"
            @click.prevent="resetUserFilter()"
            @keydown.enter.prevent="resetUserFilter()"
          />
        </div>
      </div>
      <div class="title">
        <span class="icon-filter">
          <svg
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.334 2.5H1.667l6.667 7.883v5.45l3.333 1.667v-7.117L18.334 2.5Z"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <h2
          class="subhead-lg bold"
          v-if="directoryTitle"
          v-html="directoryTitle"
        />
      </div>
      <div class="combo-wrap" v-if="!isObjEmpty(programs)">
        <label
          class="subhead-xs bold"
          for="filter-by-program"
          v-if="programLabel"
          v-html="programLabel"
        />
        <input
          type="text"
          class="combobox"
          id="filter-by-program"
          name="filter-by-program"
          :value="selectedProgram"
          inputmode="none"
          placeholder="Select"
          @select="filterByProgram"
          @change="filterByProgram"
        />
        <div class="icon-caret" v-if="filterIcon">
          <img :src="filterIcon" alt="Filter Icon" />
        </div>
        <div class="listbox select-program">
          <div
            class="option title-md"
            v-for="program in programs"
            :key="program.ID"
            :data-id="program.ID"
            v-html="program.name"
          />
        </div>
      </div>
      <div class="combo-wrap" v-if="!isObjEmpty(businesses)">
        <label
          class="subhead-xs bold"
          for="filter-by-function"
          v-html="businessLabel"
        />
        <input
          type="text"
          class="combobox"
          id="filter-by-function"
          name="filter-by-function"
          inputmode="none"
          placeholder="Select"
          :value="selectedBusiness"
          @select="filterByBusiness"
        />
        <div class="icon-caret" v-if="iconCaret">
          <img :src="iconCaret" alt="Filter Icon" />
        </div>

        <div class="listbox select-function">
          <div
            class="option title-md"
            v-for="business in businesses"
            :key="business"
            v-html="business"
          />
        </div>
      </div>
      <div class="combo-wrap" v-if="!isObjEmpty(industries)">
        <label
          class="subhead-xs bold"
          for="filter-by-industry"
          v-if="industryLabel"
          v-html="industryLabel"
        />
        <input
          type="text"
          class="combobox"
          id="filter-by-industry"
          :value="selectedIndustry"
          name="filter-by-industry"
          inputmode="none"
          placeholder="Select"
          @select="filterByIndustry"
        />
        <div class="icon-caret" v-if="filterIcon">
          <img :src="filterIcon" alt="Filter Icon" />
        </div>

        <div class="listbox select-industry">
          <div
            class="option title-md"
            v-for="industry in industries"
            :key="industry"
            v-html="industry"
          ></div>
        </div>
      </div>
      <button
        class="btn trigger-search-btn"
        v-html="applyFilter"
        @click.prevent="filterUsers()"
        @keydown.enter.prevent="filterUsers()"
      />
    </div>
  </div>
</template>
<script type="application/javascript">
/**
 * Vue Scripts
 */
import mixins from '../../Util/mixins';
/**
 * Vue Components
 */

export default {
  props: {},
  data: () => ({
    order: '',
    userProgram: '',
    userIndustry: '',
    userBusiness: '',
  }),
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {
    filterByProgram(e) {
      this.userProgram = e.target.value;
      this.$store.commit('updateDirectory', { userProgram: this.userProgram });
    },
    filterByIndustry(e) {
      this.userIndustry = e.target.value;
      this.$store.commit('updateDirectory', {
        userIndustry: this.userIndustry,
      });
    },
    filterByBusiness(e) {
      this.userBusiness = e.target.value;
      this.$store.commit('updateDirectory', {
        userBusiness: this.userBusiness,
      });
    },
    filterUsers() {
      this.$store.commit('updateState', {page: 1})
      this.$store.dispatch('getUsers', {update: true});
    },
  },
  components: {},
  computed: {
    programLabel() {
      return this.$store.state.directoryDefault.programLabel;
    },
    iconCaret() {
      return this.$store.state.icons.iconCaret;
    },
    filterIcon() {
      return this.$store.state.icons.iconFilter;
    },
    showFiltersTitle() {
      return this.$store.state.directoryDefault.showFiltersTitle;
    },
    filterTitle() {
      return this.$store.state.directoryDefault.filterTitle;
    },
    resetBtn() {
      return this.$store.state.directoryDefault.resetBtn;
    },
    industryLabel() {
      return this.$store.state.directoryDefault.industryLabel;
    },
    directoryTitle() {
      return this.$store.state.directoryDefault.directoryTitle;
    },
    businessLabel() {
      return this.$store.state.directoryDefault.businessLabel;
    },
    applyFilter() {
      return this.$store.state.directoryDefault.applyFilter;
    },
    industries() {
      return this.$store.state.directoryDefault.industries;
    },
    businesses() {
      return this.$store.state.directoryDefault.businesses;
    },
    programs() {
      return this.$store.state.directoryDefault.programYears;
    },
    selectedBusiness() {
      return this.$store.state.directory?.userBusiness;
    },
    selectedProgram() {
      return this.$store.state.directory?.userProgram;
    },
    selectedIndustry() {
      return this.$store.state.directory?.userIndustry;
    },
  },
  watch: {},
  mixins: [mixins],
  name: 'userSearch',
};
</script>
