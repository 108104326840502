<template>
    <svg viewBox="0 0 72 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <polygon id="path-1" points="5.70666207 31.0218341 0 31.0218341 0 0.0044900023 5.70666207 0.0044900023 11.4133241 0.0044900023 11.4133241 31.0218341 5.70666207 31.0218341"></polygon>
            <polygon id="path-3" points="0 31.1534047 71.9874483 31.1534047 71.9874483 0.136060676 0 0.136060676"></polygon>
        </defs>
        <g id="Mobile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="detail" transform="translate(-23.000000, -14.000000)">
                <g id="clique-logo" transform="translate(23.000000, 14.000000)">
                    <g id="Group-3" transform="translate(0.000000, 0.131571)">
                        <mask id="mask-2" fill="white">
                            <use xlink:href="#path-1"></use>
                        </mask>
                        <g id="Clip-2"></g>
                        <path d="M5.82132414,0.0044900023 C2.24601379,0.0044900023 -5.51724133e-05,2.71740382 -5.51724133e-05,6.19878832 L-5.51724133e-05,24.8272636 C-5.51724133e-05,28.3539563 2.24601379,31.021698 5.82132414,31.0218341 C9.39649655,31.0218341 11.4133241,28.4444367 11.4133241,24.9630522 L11.4133241,19.9893542 L8.52546207,19.9893542 L8.52546207,25.2795293 C8.52546207,26.8168789 7.60877241,27.9019628 5.95884138,27.9019628 C4.21704828,27.9019628 3.30035862,26.8168789 3.30035862,25.3248375 L3.30035862,5.70148655 C3.30035862,4.25461733 4.21704828,3.1243613 5.95884138,3.1243613 C7.60877241,3.1243613 8.52546207,4.25461733 8.52546207,5.74679476 L8.52546207,10.9916617 L11.4133241,10.9916617 L11.4133241,6.06327189 C11.4133241,2.58175132 9.39649655,0.0044900023 5.82132414,0.0044900023" id="Fill-1" fill="#FEFEFE" mask="url(#mask-2)"></path>
                    </g>
                    <polygon id="Fill-4" fill="#FEFEFE" points="17.5878207 0.588217513 14.2875448 0.588217513 14.2875448 30.7011663 23.7756828 30.7011663 23.7756828 27.5815671 17.5878207 27.581431"></polygon>
                    <mask id="mask-4" fill="white">
                        <use xlink:href="#path-3"></use>
                    </mask>
                    <g id="Clip-7"></g>
                    <polygon id="Fill-6" fill="#FEFEFE" mask="url(#mask-4)" points="26.145931 30.7012751 29.492 30.7012751 29.492 0.588326362 26.145931 0.588326362"></polygon>
                    <path d="M41.0750897,24.8233587 L38.9665379,26.6770493 L40.0206069,27.6267528 C39.6081931,27.8980578 39.1040552,28.0335743 38.5081931,28.0335743 C36.812331,28.0335743 35.9414345,26.85801 35.9414345,25.4563129 L35.9414345,5.83323411 C35.9414345,4.47670917 36.812469,3.25597279 38.508331,3.25597279 C40.204331,3.25597279 41.0750897,4.47670917 41.0750897,5.83323411 L41.0750897,24.8233587 Z M44.3752276,25.185008 L44.3752276,6.33053588 C44.3753655,2.80384316 42.0836414,0.136101494 38.508331,0.136101494 C34.9331586,0.136101494 32.6411586,2.80384316 32.6411586,6.33053588 L32.6411586,24.9590112 C32.6411586,28.4857039 34.9330207,31.1533095 38.5081931,31.1533095 C39.6999172,31.1533095 40.8001931,30.8820045 41.7167448,30.2943584 C42.2210207,30.8368324 43.0000552,31.1533095 43.8710897,31.1533095 C44.7419862,31.1533095 45.200331,31.0177931 45.6585379,30.7011799 L45.6585379,28.7118367 C44.6960552,28.7118367 44.0087448,28.4857039 43.6418483,28.1240546 C44.1001931,27.40062 44.3752276,26.3155361 44.3752276,25.185008 L44.3752276,25.185008 Z" id="Fill-8" fill="#FEFEFE" mask="url(#mask-4)"></path>
                    <path d="M55.7748552,25.2756516 C55.7748552,27.1293422 54.9498897,28.0789097 53.2538897,28.0789097 C51.5580276,28.0789097 50.7327862,27.1293422 50.7327862,25.2755155 L50.7329241,0.588258331 L47.4326483,0.588258331 L47.4326483,25.0945548 C47.4326483,28.8022082 49.8620276,31.1533367 53.2080966,31.1533367 C56.5080966,31.1533367 58.9834069,28.8022082 58.9834069,25.0946909 L58.9834069,0.588258331 L55.7748552,0.588258331 L55.7748552,25.2756516 Z" id="Fill-9" fill="#FEFEFE" mask="url(#mask-4)"></path>
                    <polygon id="Fill-10" fill="#FEFEFE" mask="url(#mask-4)" points="71.9874069 3.61769009 71.9875448 0.58829915 62.2703034 0.58829915 62.2703034 30.701384 71.9874069 30.701384 71.9874069 27.671993 65.5704414 27.671993 65.5704414 16.594341 70.1998207 16.594341 70.1998207 13.6552943 65.5704414 13.6552943 65.5704414 3.61769009"></polygon>
                </g>
            </g>
        </g>
    </svg>
</template>

<script type="application/javascript">
    export default {
        props: {
            logo: String,
            siteName: String,
        },
        name: "Logo"
    };
</script>

<style scoped>

</style>
