var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoading
    ? _c("div", { staticClass: "loading" }, [
        _c(
          "svg",
          {
            attrs: {
              width: "150",
              height: "150",
              viewBox: "0 0 44 44",
              xmlns: "http://www.w3.org/2000/svg",
              stroke: _vm.white ? "#ffffff" : "#009aa7",
            },
          },
          [
            _c(
              "g",
              {
                attrs: {
                  fill: "none",
                  "fill-rule": "evenodd",
                  "stroke-width": "2",
                },
              },
              [
                _c("circle", { attrs: { cx: "22", cy: "22", r: "1" } }, [
                  _c("animate", {
                    attrs: {
                      attributeName: "r",
                      begin: "0s",
                      dur: "1.8s",
                      values: "1; 20",
                      calcMode: "spline",
                      keyTimes: "0; 1",
                      keySplines: "0.165, 0.84, 0.44, 1",
                      repeatCount: "indefinite",
                    },
                  }),
                  _vm._v(" "),
                  _c("animate", {
                    attrs: {
                      attributeName: "stroke-opacity",
                      begin: "0s",
                      dur: "1.8s",
                      values: "1; 0",
                      calcMode: "spline",
                      keyTimes: "0; 1",
                      keySplines: "0.3, 0.61, 0.355, 1",
                      repeatCount: "indefinite",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("circle", { attrs: { cx: "22", cy: "22", r: "1" } }, [
                  _c("animate", {
                    attrs: {
                      attributeName: "r",
                      begin: "-0.9s",
                      dur: "1.8s",
                      values: "1; 20",
                      calcMode: "spline",
                      keyTimes: "0; 1",
                      keySplines: "0.165, 0.84, 0.44, 1",
                      repeatCount: "indefinite",
                    },
                  }),
                  _vm._v(" "),
                  _c("animate", {
                    attrs: {
                      attributeName: "stroke-opacity",
                      begin: "-0.9s",
                      dur: "1.8s",
                      values: "1; 0",
                      calcMode: "spline",
                      keyTimes: "0; 1",
                      keySplines: "0.3, 0.61, 0.355, 1",
                      repeatCount: "indefinite",
                    },
                  }),
                ]),
              ]
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }