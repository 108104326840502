var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "left" }, [
      _vm.preferredName
        ? _c("div", { staticClass: "detail-indiv" }, [
            _vm.preferredLabel
              ? _c("p", {
                  staticClass: "subhead-xs bold",
                  domProps: { innerHTML: _vm._s(_vm.preferredLabel) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("p", {
              staticClass: "copy",
              domProps: { innerHTML: _vm._s(_vm.preferredName) },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.industry
        ? _c("div", { staticClass: "detail-indiv" }, [
            _vm.industryLabel
              ? _c("p", {
                  staticClass: "subhead-xs bold",
                  domProps: { innerHTML: _vm._s(_vm.industryLabel) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("p", {
              staticClass: "copy",
              domProps: { innerHTML: _vm._s(_vm.industry) },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.primaryBusiness
        ? _c("div", { staticClass: "detail-indiv" }, [
            _vm.primaryBusinessLabel
              ? _c("p", {
                  staticClass: "subhead-xs bold",
                  domProps: { innerHTML: _vm._s(_vm.primaryBusinessLabel) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("p", {
              staticClass: "copy",
              domProps: { innerHTML: _vm._s(_vm.primaryBusiness) },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.linkedinBtn && _vm.linkedinUrl
        ? _c("div", { staticClass: "detail-indiv" }, [
            _vm.linkedinLabel
              ? _c("p", {
                  staticClass: "subhead-xs bold",
                  domProps: { innerHTML: _vm._s(_vm.linkedinLabel) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("a", {
              staticClass: "copy bold",
              attrs: { href: _vm.linkedinUrl, target: "_blank" },
              domProps: { innerHTML: _vm._s(_vm.linkedinBtn) },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.privateBoard
        ? _c("div", { staticClass: "detail-indiv" }, [
            _vm.privateBoardLabel
              ? _c("p", {
                  staticClass: "subhead-xs bold",
                  domProps: { innerHTML: _vm._s(_vm.privateBoardLabel) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("p", {
              staticClass: "copy",
              domProps: { innerHTML: _vm._s(_vm.privateBoard) },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.nonprofitBoard
        ? _c("div", { staticClass: "detail-indiv" }, [
            _vm.nonprofitBoardLabel
              ? _c("p", {
                  staticClass: "subhead-xs bold",
                  domProps: { innerHTML: _vm._s(_vm.nonprofitBoardLabel) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("p", {
              staticClass: "copy",
              domProps: { innerHTML: _vm._s(_vm.nonprofitBoard) },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.corporateBoard
        ? _c("div", { staticClass: "detail-indiv" }, [
            _vm.corporateBoardLabel
              ? _c("p", {
                  staticClass: "subhead-xs bold",
                  domProps: { innerHTML: _vm._s(_vm.corporateBoardLabel) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("p", {
              staticClass: "copy",
              domProps: { innerHTML: _vm._s(_vm.corporateBoard) },
            }),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "right" }, [
      _vm.email
        ? _c("div", { staticClass: "detail-indiv" }, [
            _vm.emailLabel
              ? _c("p", {
                  staticClass: "subhead-xs bold",
                  domProps: { innerHTML: _vm._s(_vm.emailLabel) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("a", {
              staticClass: "copy bold",
              attrs: { href: "mailto:" + _vm.email },
              domProps: { innerHTML: _vm._s(_vm.email) },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.businessPhone
        ? _c("div", { staticClass: "detail-indiv" }, [
            _vm.phoneLabel
              ? _c("p", {
                  staticClass: "subhead-xs bold",
                  domProps: { innerHTML: _vm._s(_vm.phoneLabel) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("a", {
              staticClass: "copy",
              attrs: { href: "tel:" + _vm.businessPhone },
              domProps: { innerHTML: _vm._s(_vm.businessPhone) },
            }),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }