var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.maxPages > 1 && !_vm.loading
    ? _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("Pagination", {
            attrs: {
              "active-class": _vm.paginationOpts.activeClass,
              "click-handler": _vm.gotoPage,
              "margin-pages": 1,
              "next-link-class": _vm.paginationOpts.nextLinkClass,
              "next-class": _vm.paginationOpts.nextClass,
              "prev-class": _vm.paginationOpts.prevClass,
              "next-text": _vm.paginationOpts.next,
              "page-count": _vm.maxPages,
              "container-class": _vm.paginationOpts.containerClass,
              "page-link-class": _vm.paginationOpts.linkClass,
              "page-range": 3,
              "page-class": _vm.paginationOpts.pageClass,
              "prev-link-class": _vm.paginationOpts.prevLinkClass,
              "prev-text": _vm.paginationOpts.prev,
              value: _vm.page,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }