import Vue from "vue";
import Vuex from "vuex";

import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        apiUrls: {
            directory: typeof DIRECTORY !== 'undefined' && DIRECTORY?.apiUrl || null,
            userProfile: typeof USERPROFILE !== 'undefined' && USERPROFILE?.apiUrl || null,
        },
        directoryDefault: {
            filterOptions: typeof DIRECTORY !== 'undefined' && DIRECTORY?.filterOptions || {},
            industries: typeof DIRECTORY !== 'undefined' && DIRECTORY?.industries || {},
            businesses: typeof DIRECTORY !== 'undefined' && DIRECTORY?.businesses || {},
            programYears: typeof DIRECTORY !== 'undefined' && DIRECTORY?.programYears || {},
            letters: typeof DIRECTORY !== 'undefined' && DIRECTORY?.letters || {},
            filterTitle: typeof DIRECTORY !== 'undefined' && DIRECTORY?.filterTitle || null,
            showFiltersTitle: typeof DIRECTORY !== 'undefined' && DIRECTORY?.showFiltersTitle || null,
            noResults: typeof DIRECTORY !== 'undefined' && DIRECTORY?.noResults || '',
            searchButton: typeof DIRECTORY !== 'undefined' && DIRECTORY?.searchButton || 'Search',
            searchLabel: typeof DIRECTORY !== 'undefined' && DIRECTORY?.searchLabel || null,
            searchPlaceholder: typeof DIRECTORY !== 'undefined' && DIRECTORY?.searchPlaceholder || null,
            searchTitle: typeof DIRECTORY !== 'undefined' && DIRECTORY?.searchTitle || null,
            resetBtn: typeof DIRECTORY !== 'undefined' && DIRECTORY?.resetBtn || null,
            programLabel: typeof DIRECTORY !== 'undefined' && DIRECTORY?.programLabel || null,
            directoryTitle: typeof DIRECTORY !== 'undefined' && DIRECTORY?.directoryTitle || null,
            industryLabel: typeof DIRECTORY !== 'undefined' && DIRECTORY?.industryLabel || null,
            businessLabel: typeof DIRECTORY !== 'undefined' && DIRECTORY?.businessLabel || null,
            applyFilter: typeof DIRECTORY !== 'undefined' && DIRECTORY?.applyFilter || null,
            businessTitle: typeof DIRECTORY !== 'undefined' && DIRECTORY?.businessTitle || null,
            sortLabel: typeof DIRECTORY !== 'undefined' && DIRECTORY?.sortLabel || 'Sort By',
        },
        userProfile: {
            directoryBtn: typeof USERPROFILE !== 'undefined' && USERPROFILE?.directoryBtn || null,
            editBtn: typeof USERPROFILE !== 'undefined' && USERPROFILE?.editBtn || [],
            preferredLabel: typeof USERPROFILE !== 'undefined' && USERPROFILE?.preferredLabel || null,
            emailLabel: typeof USERPROFILE !== 'undefined' && USERPROFILE?.emailLabel || null,
            industryLabel: typeof USERPROFILE !== 'undefined' && USERPROFILE?.industryLabel || null,
            //personalEmailLabel: typeof USERPROFILE !== 'undefined' && USERPROFILE?.personalEmailLabel || null,
            primaryBusinessLabel: typeof USERPROFILE !== 'undefined' && USERPROFILE?.primaryBusinessLabel || null,
            phoneLabel: typeof USERPROFILE !== 'undefined' && USERPROFILE?.phoneLabel || null,
            linkedinLabel: typeof USERPROFILE !== 'undefined' && USERPROFILE?.linkedinLabel || null,
            linkedinBtn: typeof USERPROFILE !== 'undefined' && USERPROFILE?.linkedinBtn || null,
            //mobileLabel: typeof USERPROFILE !== 'undefined' && USERPROFILE?.mobileLabel || null,
            userNotFound: typeof USERPROFILE !== 'undefined' && USERPROFILE?.userNotFound || null,
            userDetails: typeof USERPROFILE !== 'undefined' && USERPROFILE?.userDetails || null,
            privateBoardLabel: typeof USERPROFILE !== 'undefined' && USERPROFILE?.privateBoardLabel || null,
            nonprofitBoardLabel: typeof USERPROFILE !== 'undefined' && USERPROFILE?.nonprofitBoardLabel || null,
            corporateBoardLabel: typeof USERPROFILE !== 'undefined' && USERPROFILE?.corporateBoardLabel || null,
        },
        directory: {
            userTitle: '',
            orderBy: '',
            skipTo: '',
            userBusiness: '',
            userProgram: '',
            userIndustry: '',
        },
        profile: {
            userId: '',
        },
        icons: {
			navArrowWhite: typeof GLOBAL !== 'undefined' && GLOBAL.icons?.navArrowWhite || null,
			iconCaret: typeof GLOBAL !== 'undefined' && GLOBAL.icons?.iconCaret || null,
			iconFilter: typeof GLOBAL !== 'undefined' && GLOBAL.icons?.iconFilter || null,
			iconGear: typeof GLOBAL !== 'undefined' && GLOBAL.icons?.iconGear || null,
		},
        loading: false,
        page: 1,
        maxPages: 0,
		posts: [],
		users: [],
		queryParams: {},
        searchParams: null,
        searchText: "",

        desktopNav: [],
        mobileNav: [],
    },
    mutations,
    actions,
    getters,
});
