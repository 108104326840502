var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal", attrs: { role: "alertdialog" } }, [
    _c("div", {
      staticClass: "modal__backdrop",
      attrs: { tabindex: "-1" },
      on: { click: _vm.closeModal },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "modal__dialog" }, [
      _c(
        "div",
        { staticClass: "modal__header" },
        [
          _c("h2", [_vm._v("Header")]),
          _vm._v(" "),
          _vm._t("header"),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "modal__close",
              attrs: { type: "button", tabindex: "-1" },
              on: { click: _vm.closeModal },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "modal__close__svg",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 352 512",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "currentColor",
                      d: "M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z",
                    },
                  }),
                ]
              ),
            ]
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal__body" }, [
      _c("p", [
        _vm._v(
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal__footer" }, [
      _c("p", [_vm._v("Donec nec justo eget felis facilisis fermentum.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }