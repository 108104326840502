var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "banner-content" }, [
    !_vm.isObjEmpty(_vm.image) || _vm.image.length
      ? _c("img", {
          staticClass: "profile-photo",
          attrs: {
            src: _vm.image.src,
            "data-src": _vm.image.src,
            content: _vm.image.src,
            alt: _vm.image.alt,
            role: "img",
            property: "v:image",
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "banner-text" },
      [
        _vm._l(_vm.programs, function ({ type, year }) {
          return !_vm.isObjEmpty(_vm.programs) || _vm.programs.length
            ? _c("p", { staticClass: "subhead-xs white" }, [
                _vm._v(_vm._s(type) + " " + _vm._s(year)),
              ])
            : _vm._e()
        }),
        _vm._v(" "),
        _vm.fullName
          ? _c("h1", {
              staticClass: "white",
              domProps: { innerHTML: _vm._s(_vm.fullName) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.jobTitle
          ? _c("p", {
              staticClass: "subhead-lg white",
              domProps: { innerHTML: _vm._s(_vm.jobTitle) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.companyName
          ? _c("p", {
              staticClass: "subhead-lg white",
              domProps: { innerHTML: _vm._s(_vm.companyName) },
            })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }