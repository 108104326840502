export default {
    methods: {
        /**
         * Ensures the object is that, an object
         * @param obj The variable to check its type
         * @returns {boolean}
         */
        isObject: (obj) => Object.prototype.toString.call(obj) === '[object Object]',
        /**
         * Returns whether the object is empty or not
         *
         * @param obj The object to map through
         * @returns {boolean}
         */
        isObjEmpty(obj) {
            return Object.keys(obj).length === 0;
        },

        isActive(postId, id) {
            return postId === id ? "active" : "";
        },
        listItemClass(postId, id, classes, children = 0) {
            let returnClass = "";
            classes ? returnClass += `${classes}` : null;
            children.length ? returnClass += ` has-children` : null;
            postId === id ? returnClass += " current-page" : null;
            return returnClass;
        },
        objHasKey(obj, key) {
            return obj.hasOwnProperty(key);
        },
        /**
        * Fetches the Search Users, with the passed in State Params
        *
        * @param {object}  updateStateParams   The updateState parameters
        */
        fetchUsers(updateStateParams) {
            this.$store.commit('updateState', updateStateParams)
            const payload = {
                update: true,
            };
            console.log(updateStateParams);
            // Search with history
            this.$store.dispatch('getUsers', payload)
        },
        /**
        * Reset User Search Filter
        *
          */
        resetUserFilter() {
            const payload = {
                update: true,
            };
            const userParams={
                orderBy:'',
                skipTo:'',
                orderByUser:'',
                userProgram:'',
                userIndustry:'',
                userBusiness:'',
            };
            const searchParams={
                searchText:'',
            };
            this.$store.commit('updateState',  searchParams)
            this.$store.commit('updateDirectory',  userParams)
            this.$store.dispatch('getUsers', payload)
        },
        scrollTo(elmTarget, classIdName = '') {
            console.log(elmTarget);            
            if (elmTarget.classList.contains("page-numbers")) {
                $("html, body").animate({
                    scrollTop: $(classIdName).offset().top
                }, 1000);
              
            }
        }
    },
};
