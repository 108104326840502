/**
 * A file that contains most of the Vue Components and logic
 */
import Vue from "vue";
import { store } from "./Vuex/store.js";
import { MediaQueries } from "vue-media-queries";
import VueAccessibleModal from "vue-accessible-modal";

window.Vue = Vue;
const mediaQueries = new MediaQueries();
Vue.use(mediaQueries);
const bodyClassList = document.body.classList;

/** Vue Accessibility Default Options **/
const modalOptions = {
	transition: 'fade',
};
Vue.use(VueAccessibleModal, modalOptions);

Vue.config.productionTip = false;

const navigationElm = document.getElementById("vue-navigation-container");

const VueInstance = new Vue({
	store,
	mediaQueries: mediaQueries,
	created() {
		if (typeof NAV !== "undefined" && navigationElm !== null) {
			this.$store.dispatch('getNavigation', { viewport: "desktop", navLocation: NAV.navLocation });
			this.$store.dispatch('getNavigation', { viewport: "mobile", navLocation: NAV.navLocation });
		}
	}
});

/**
 * The Navigation
 */
if (navigationElm !== null) {
	const navigationContainer = Vue.component("NavigationContainer", require('./Components/Navigation.vue').default);
	const navigationElm = new Vue({
		el: "#vue-navigation-container",
		mediaQueries: mediaQueries,
		store,
		render: h => h(navigationContainer)
	});
}

/**
 * Example component. Delete if not needing to reference, as well as ModalContainer.vue and the Modal folder
 */
if (document.getElementById("modal-cnt") !== null) {
	const ModalWindow = Vue.component("modal", require('./Components/ModalContainer.vue').default);
	const modalElm = new Vue({
		el: "#modal-cnt",
		render: h => h(ModalWindow)
	});
}
//user Search
if (bodyClassList.contains("template-directory")) {
	const userListingComponent = Vue.component("UserListing", require("./Page/Directory/UserListing").default);
	const userPaginationComponent = Vue.component("UserPagination", require("./Page/Directory/UserPagination").default);
	const userSearchComponent = Vue.component("UserSearch", require("./Page/Directory/UserSearch").default);
	const userFilterComponent = Vue.component("UserSearch", require("./Page/Directory/UserFilter").default);

	const userBarElm = new Vue({
		el: "#vue-search-form",
		mediaQueries: mediaQueries,
		store,
		render: h => h(userSearchComponent)
	});
	const userFilterElm = new Vue({
		el: "#vue-sidebar-filter",
		mediaQueries: mediaQueries,
		store,
		render: h => h(userFilterComponent)
	});
	const userListingElm = new Vue({
		el: "#vue-user-listing",
		mediaQueries: mediaQueries,
		store,
		created() {
			this.$store.commit("buildQuery");
		},
		beforeMount() {
			this.$store.dispatch("getUsers");
		},
		computed: {
			queryParams() {
				return this.$store.state.queryParams
			},
		},
		render: h => h(userListingComponent)
	});

	const userPaginationElm = new Vue({
		el: "#vue-user-pagination",
		mediaQueries: mediaQueries,
		store,
		render: h => h(userPaginationComponent)
	});
}
//user PROFILE
if (bodyClassList.contains("template-directory-profile")) {
	const useruserContainerComponent = Vue.component("UserBanner", require("./Page/UserProfile/UserContainer").default);

	const userBarElm = new Vue({
		el: "#vue-user-details-container",
		mediaQueries: mediaQueries,
		store,
		// created() {
		// 	this.$store.commit("buildQuery");
		// 	const {user_id} = this.queryParams;
		// 	if (user_id) this.$store.commit('updateProfile', {userId: user_id});
		// 	this.$store.dispatch("getUserProfile");
		// },
		// computed: {
		// 	queryParams() {
		// 		return this.$store.state.queryParams
		// 	},
		// },
		beforeMount() {
			//this.$store.dispatch("getUserProfile");
		},
		render: h => h(useruserContainerComponent)
	});
	// const userFilterElm = new Vue({
	// 	el: "#vue-user-details",
	// 	mediaQueries: mediaQueries,
	// 	store,
	// 	created() {
	// 		this.$store.commit("buildQuery");
	// 	},
	// 	// beforeMount() {
	// 	// 	this.$store.dispatch("getUserProfile");
	// 	// },
	// 	render: h => h(userDetailsComponent)
	// });

}
//endregion
