
<template>
    <div v-if="maxPages > 1 && !loading"
         class="pagination">
        <Pagination
            :active-class="paginationOpts.activeClass"
            :click-handler="gotoPage"
            :margin-pages="1"
            :next-link-class="paginationOpts.nextLinkClass"
            :next-class="paginationOpts.nextClass"
            :prev-class="paginationOpts.prevClass"
            :next-text="paginationOpts.next"
            :page-count="maxPages"
            :container-class="paginationOpts.containerClass"
            :page-link-class="paginationOpts.linkClass"
            :page-range="3"
            :page-class="paginationOpts.pageClass"
            :prev-link-class="paginationOpts.prevLinkClass"
            :prev-text="paginationOpts.prev"
            :value="page"
        />
    </div>
</template>

<script type="application/javascript">
    /**
     * Vue Plugins
     */
    import Pagination from "vuejs-paginate";

    /**
     * Vue Scripts
     */
    import mixins from "../../Util/mixins";
    
    export default {
        props: {},
        data: () => ({
            paginationOpts: {               
                activeClass: "current",
                containerClass: "pages",
                pageClass: "page",
                breakViewText: "&hellip;",
                linkClass: 'page-numbers',
                nextLinkClass: 'btn icon page-numbers',
                nextClass: '',
                prevClass: '',
                prevLinkClass: 'btn icon page-numbers',
                next: `<span class="inner">
                                        <span class="text">Next</span>
                                        <span class="arrow"><svg class="icon-arrow" viewBox="0 0 20 20" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                      <path d="M4.167 10h11.667M10 4.167 15.833 10 10 15.833" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                </svg></span>
                                    </span>`,
                prev: `<div class="prev tblt-only">                                    
                                    <span class="inner">
                                        <span class="arrow flipped"><svg class="icon-arrow" viewBox="0 0 20 20"
                                                                         fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M4.167 10h11.667M10 4.167 15.833 10 10 15.833" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                </svg></span>
                                        <span class="text">Previous</span>
                                    </span>
                                                   </div>`,
            },
        }),
        beforeCreate() {},
        created() {
        
        },
        beforeMount() {},
        mounted() {},
        methods: {
            gotoPage(pageNum) {
                // Scroll To Event
                console.log("asdasd");
                    event !== undefined && this.scrollTo(event.currentTarget, '.main-content')
                    this.fetchUsers({page: pageNum});
             
            }
        },
        components: {Pagination},
        computed: {
            loading() {return this.$store.state.loading},
            maxPages() {return this.$store.state.maxPages},
            page() {return this.$store.state.page},
        },
        watch: {},
        mixins: [mixins],
        name: "UserPagination"
    };
</script>
