<template>
    <div id="user-results">
           <Loading :is-loading="loading"/>
        <UserItem v-if="users.length && !loading"
                    v-for="({headshot, first_name,last_name, id, job_title, company_name,industry,primary_business_function,programs,permalink}) in users"
                    :key="id"
                    :permalink="permalink"
                    :image="headshot"
                    :first_name="first_name"
                    :last_name="last_name"
                    :jobTitle="job_title"
                    :industry="industry"
                    :company_name="company_name"
                    :primaryBusiness="primary_business_function"
                    :programs="programs"
                    />
        
        <NoResults v-if="!users.length && !loading"
                   container-class="no-posts"
                   paragraph-class="result-item no-posts__text"
                   :no-results-text="noResults"/>
                </div>
</template>
<script type="application/javascript">
    /**
     * Vue Components
     */
    import UserItem from "../../Components/Blocks/UserItem.vue";
    import Loading from "../../Components/Blocks/Loading.vue";
    import NoResults from "../../Components/Blocks/NoResults";

    export default {
        props: {},
        data: () => ({}),
        beforeCreate() {},
        created() {},
        beforeMount() {},
        mounted() {},
        methods: {},
        components: {Loading, UserItem, NoResults},
        computed: {
            loading() {return this.$store.state.loading},
            noResults() {return this.$store.state.directoryDefault?.noResults},
            users() {return this.$store.state.users},
        },
        watch: {},
        name: "SearchListing"
    };
</script>