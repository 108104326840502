var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.containerClass }, [
    _c("p", {
      class: _vm.paragraphClass,
      domProps: { textContent: _vm._s(_vm.noResultsText) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }