var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.menuOpen,
          expression: "menuOpen",
        },
      ],
      staticClass: "topnav vue-mobile-navigation mobile-navigation",
    },
    [
      _c(
        "ul",
        { staticClass: "header__dropdown" },
        _vm._l(_vm.menu, function (item) {
          return _c("Link", {
            key: item.id,
            attrs: { "menu-item": item, "post-id": _vm.postId },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }