var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "directory-entry" }, [
    _c("div", { staticClass: "image" }, [
      !_vm.isObjEmpty(_vm.image) || _vm.image.length
        ? _c("div", [_c("PostImage", { attrs: { image: _vm.image } })], 1)
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "text" }, [
      _c("div", { staticClass: "left" }, [
        _c(
          "a",
          { staticClass: "subhead-lg bold", attrs: { href: _vm.permalink } },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.first_name) +
                " " +
                _vm._s(_vm.last_name) +
                "\n      "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.jobTitle
          ? _c("p", { staticClass: "copy-sm" }, [_vm._v(_vm._s(_vm.jobTitle))])
          : _vm._e(),
        _vm._v(" "),
        _vm.company_name
          ? _c("p", { staticClass: "copy-sm italic" }, [
              _vm._v(_vm._s(_vm.company_name)),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right" },
        [
          _vm._l(_vm.programs, function ({ type, year }) {
            return !_vm.isObjEmpty(_vm.programs) || _vm.programs.length
              ? _c("p", { staticClass: "subhead-xs bold" }, [
                  _vm._v(_vm._s(type) + " " + _vm._s(year)),
                ])
              : _vm._e()
          }),
          _vm._v(" "),
          _vm.primaryBusiness
            ? _c("p", { staticClass: "copy-sm" }, [
                _c("span", {
                  staticClass: "italic",
                  domProps: { innerHTML: _vm._s(_vm.businessTitle) },
                }),
                _vm._v("| " + _vm._s(_vm.primaryBusiness) + "\n      "),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }