<template>
  <div class="banner-content">
    <img
      v-if="!isObjEmpty(image) || image.length"
      :src="image.src"
      :data-src="image.src"
      class="profile-photo"
      :content="image.src"
      :alt="image.alt"
      role="img"
      property="v:image"
    />
    <div class="banner-text">
      <p class="subhead-xs white" v-if="!isObjEmpty(programs) || programs.length" v-for="{type,year} in programs">{{type}} {{year }}</p>
      <h1 class="white" v-if="fullName" v-html="fullName" />
      <p class="subhead-lg white" v-if="jobTitle" v-html="jobTitle" />
      <p class="subhead-lg white" v-if="companyName" v-html="companyName" />
    </div>   
  </div>
</template>
<script type="application/javascript">
/**
* Vue Scripts
*/

import mixins from '../../Util/mixins';
/**
* Vue Components
*/

export default {
props: {
  image: [Array, Object],
  fullName: {
    type: String,
    default: '',
  },
  jobTitle: {
    type: String,
    default: '',
  },
  companyName: {
    type: String,
    default: '',
  },
  programs: [Array, Object],

},
data: () => ({}),
beforeCreate() {},
created() {},
beforeMount() {},
mounted() {},
methods: {},
components: {  },
computed: { 
},
watch: {},
mixins: [mixins],
name: 'userBanner',
};
</script>
