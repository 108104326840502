var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isObjEmpty(_vm.image)
    ? _c("picture", [
        _vm.image.webpSrcset
          ? _c("source", {
              attrs: {
                srcset: _vm.image.webpSrcset,
                sizes: _vm.image.sizes,
                type: "image/webp",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("source", {
          attrs: {
            srcset: _vm.image.srcset,
            sizes: _vm.image.sizes,
            type: _vm.image.type,
          },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "img-inner",
          attrs: {
            src: _vm.image.src,
            "data-src": _vm.image.src,
            content: _vm.image.src,
            alt: _vm.image.alt,
            role: "img",
            property: "v:image",
          },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }