var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c("label", {
      staticClass: "subhead-xs bold mbl-lptp-only",
      attrs: { for: "search" },
      domProps: { innerHTML: _vm._s(_vm.searchPlaceholder) },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "combo-wrap sort-by" }, [
      _c("label", {
        staticClass: "subhead-xs bold",
        attrs: { for: "sort-by" },
        domProps: { innerHTML: _vm._s(_vm.sortLabel) },
      }),
      _vm._v(" "),
      _c("input", {
        staticClass: "combobox",
        attrs: {
          type: "text",
          id: "sort-by",
          name: "sort-by",
          inputmode: "none",
          placeholder: "Select",
        },
        domProps: { value: _vm.selectedSort },
        on: { select: _vm.orderByUser },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "icon-caret" }, [
        _c(
          "svg",
          {
            staticClass: "caret",
            attrs: {
              viewBox: "0 0 10 6",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [_c("path", { attrs: { d: "m1 1 4 4 4-4" } })]
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "search-wrap" }, [
      _c("div", { staticClass: "search-input-wrap" }, [
        _c("span", { staticClass: "icon icon-search" }, [
          _c(
            "svg",
            {
              attrs: {
                viewBox: "0 0 12 12",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M5.5 9.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM10.5 10.5 8.325 8.325",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchForText,
              expression: "searchForText",
            },
          ],
          staticClass: "search",
          attrs: { type: "text", id: "search", name: "search" },
          domProps: { value: _vm.searchForText },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.searchForText = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "placeholder-text",
          domProps: { innerHTML: _vm._s(_vm.searchPlaceholder) },
        }),
      ]),
      _vm._v(" "),
      _c("button", {
        staticClass: "btn trigger-search-btn",
        domProps: { textContent: _vm._s(_vm.searchButton) },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.fetchUsers({
              page: 1,
              searchText: _vm.searchForText || "",
            })
          },
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            $event.preventDefault()
            return _vm.fetchUsers({
              page: 1,
              searchText: _vm.searchForText || "",
            })
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "combo-wrap mbl-lptp-only" }, [
      _c(
        "label",
        { staticClass: "subhead-xs bold", attrs: { for: "skip-to" } },
        [_vm._v("Skip to")]
      ),
      _vm._v(" "),
      _c("input", {
        staticClass: "combobox",
        attrs: {
          type: "text",
          id: "skip-to",
          name: "skip-to",
          inputmode: "none",
          placeholder: "Select",
        },
        domProps: { value: _vm.selectedSkipTo },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "icon-caret" }, [
        _c(
          "svg",
          {
            staticClass: "caret",
            attrs: {
              viewBox: "0 0 10 6",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [_c("path", { attrs: { d: "m1 1 4 4 4-4" } })]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "listbox select-skip" },
        _vm._l(_vm.letters, function (alpha) {
          return _c(
            "div",
            {
              key: alpha,
              staticClass: "option title-md",
              attrs: { letter: alpha },
            },
            [_vm._v("\n        Last Name " + _vm._s(alpha) + "\n      ")]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "skip-to lptp-lg-only" }, [
      _c("p", { staticClass: "subhead-xs" }, [_vm._v("Skip To")]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "alphabet-list no-bullets" },
        _vm._l(_vm.alphabets, function (alpha) {
          return _c("li", { key: alpha }, [
            _c("a", { attrs: { href: "#", letter: alpha } }, [
              _vm._v(_vm._s(alpha)),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "listbox select-sort" }, [
      _c(
        "div",
        { staticClass: "option title-md", attrs: { "data-value": "lastasc" } },
        [_vm._v("Last Name A - Z")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "option title-md", attrs: { "data-value": "lastdesc" } },
        [_vm._v("Last Name Z - A")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "option title-md", attrs: { "data-value": "firstasc" } },
        [_vm._v("\n        First Name A - Z\n      ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "option title-md",
          attrs: { "data-value": "firstdesc" },
        },
        [_vm._v("\n        First Name Z - A\n      ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }