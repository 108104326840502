<template>
  <div class="directory-entry">
    <div class="image">
        <div v-if="!isObjEmpty(image) || image.length">
                <PostImage :image="image" />
            </div>
    </div>
    <div class="text">
      <div class="left">
        <a :href="permalink" class="subhead-lg bold">
          {{ first_name }} {{ last_name }}
        </a>
        <p class="copy-sm" v-if="jobTitle">{{ jobTitle }}</p>
        <p class="copy-sm italic" v-if="company_name">{{ company_name }}</p>
      </div>
      <div class="right">
        <p class="subhead-xs bold" v-if="!isObjEmpty(programs) || programs.length" v-for="{type,year} in programs">{{type}} {{year }}</p>
        <p class="copy-sm"  v-if="primaryBusiness"><span class="italic" v-html="businessTitle" />| {{primaryBusiness}}
        </p>
      </div>
    </div>
  </div>
</template>

<script type="application/javascript">
import mixins from '../../Util/mixins.js';
/**
 * Vue Components
 */
import PostImage from './PostImage.vue';
export default {
  components: { PostImage },
  props: {
    image: [Array, Object],
    programs: [Array, Object],
    permalink: {
      type: String,
      default: '',
    },
    first_name: {
      type: String,
      default: '',
    },
    last_name: {
      type: String,
      default: '',
    },
    jobTitle: {
      type: String,
      default: '',
    },
    company_name: {
      type: String,
      default: '',
    },
    classYear: {
      type: String,
      default: '',
    },
    program: {
      type: String,
      default: '',
    },
    primaryBusiness: {
      type: String,
      default: '',
    },
    secondaryBusiness: {
      type: String,
      default: '',
    },
  },
  data: () => ({}),
  mixins: [mixins],
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {},
  computed: {
    businessTitle(){
      return this.$store.state.directoryDefault.businessTitle;
    }
  },
  watch: {},
  name: 'UserItem',
};
</script>
