<template >
  <div class="wrapper">
    <div class="left">
      <div class="detail-indiv" v-if="preferredName">
        <p class="subhead-xs bold" v-if="preferredLabel" v-html="preferredLabel"/>
        <p class="copy" v-html="preferredName"/>
      </div>
      <div class="detail-indiv" v-if="industry">
        <p class="subhead-xs bold" v-if="industryLabel" v-html="industryLabel"/>
        <p class="copy" v-html="industry"/>
      </div>
      <div class="detail-indiv" v-if="primaryBusiness">
        <p class="subhead-xs bold" v-if="primaryBusinessLabel" v-html="primaryBusinessLabel"/>
        <p class="copy" v-html="primaryBusiness"/>
      </div>
      <div class="detail-indiv" v-if="linkedinBtn && linkedinUrl">
        <p class="subhead-xs bold" v-if="linkedinLabel" v-html="linkedinLabel"/>
        <a :href="linkedinUrl" target="_blank" class="copy bold" v-html="linkedinBtn"/>
      </div>
        <div class="detail-indiv" v-if="privateBoard">
            <p class="subhead-xs bold" v-if="privateBoardLabel" v-html="privateBoardLabel"/>
            <p class="copy" v-html="privateBoard"/>
        </div>
        <div class="detail-indiv" v-if="nonprofitBoard">
            <p class="subhead-xs bold" v-if="nonprofitBoardLabel" v-html="nonprofitBoardLabel"/>
            <p class="copy" v-html="nonprofitBoard"/>
        </div>
        <div class="detail-indiv" v-if="corporateBoard">
            <p class="subhead-xs bold" v-if="corporateBoardLabel" v-html="corporateBoardLabel"/>
            <p class="copy" v-html="corporateBoard"/>
        </div>
    </div>

    <div class="right">
      <div class="detail-indiv" v-if="email">
        <p class="subhead-xs bold" v-if="emailLabel" v-html="emailLabel"/>
        <a :href="'mailto:'+email" class="copy bold"  v-html="email"/>
      </div>
      <div class="detail-indiv" v-if="businessPhone">
        <p class="subhead-xs bold" v-if="phoneLabel" v-html="phoneLabel"/>
        <a :href="'tel:'+businessPhone" class="copy" v-html="businessPhone"/>
      </div>
    </div>
  </div>

</template>
<script type="application/javascript">
/**
 * Vue Components
 */
export default {
  props: {
    preferredName: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    industry: {
      type: String,
      default: '',
    },
    primaryBusiness: {
      type: String,
      default: '',
    },
    linkedinUrl: {
      type: String,
      default: '',
    },
    classYear: {
      type: String,
      default: '',
    },
    businessPhone: {
      type: String,
      default: '',
    },
    mobilePhone: {
      type: String,
      default: '',
    },
      corporateBoard: {
      type: String,
      default: '',
    },
      privateBoard: {
      type: String,
      default: '',
    },
      nonprofitBoard: {
      type: String,
      default: '',
    },
  },
  data: () => ({}),
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {},
  components: {},
  computed: {
    noResults() {
      return this.$store.state.userProfile?.notFound;
    },
    emailLabel() {
      return this.$store.state.userProfile.emailLabel;
    },
    industryLabel() {
      return this.$store.state.userProfile.industryLabel;
    },
/*    personalEmailLabel() {
      return this.$store.state.userProfile.personalEmailLabel;
    },*/
    primaryBusinessLabel() {
      return this.$store.state.userProfile.primaryBusinessLabel;
    },
    preferredLabel() {
      return this.$store.state.userProfile.preferredLabel;
    },
    phoneLabel() {
      return this.$store.state.userProfile.phoneLabel;
    },
    linkedinLabel() {
      return this.$store.state.userProfile.linkedinLabel;
    },
    linkedinBtn() {
      return this.$store.state.userProfile.linkedinBtn;
    },
/*    mobileLabel() {
      return this.$store.state.userProfile.mobileLabel;
    },*/
     corporateBoardLabel() {
      return this.$store.state.userProfile.corporateBoardLabel;
    },
      nonprofitBoardLabel() {
      return this.$store.state.userProfile.nonprofitBoardLabel;
    },
      privateBoardLabel() {
      return this.$store.state.userProfile.privateBoardLabel;
    },
    userDetails() {
      return this.$store.state.userProfile.userDetails.userDetails;
    },
  },
  watch: {},
  name: 'UserDetails',
};
</script>
