var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "topnav desktop-navigation" }, [
    _c(
      "a",
      { staticClass: "logo", attrs: { href: _vm.homeUrl } },
      [_c("Logo")],
      1
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "header__nav" },
      _vm._l(_vm.menu, function (item) {
        return _c("Link", {
          key: item.id,
          attrs: { "menu-item": item, "post-id": _vm.postId },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }