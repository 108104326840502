var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header__wrap" }, [
    _c(
      "a",
      { staticClass: "logo", attrs: { href: _vm.homeUrl } },
      [_c("Logo")],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "hamburger-container" }, [
      _c(
        "button",
        { staticClass: "hamburger", class: { "is-active": _vm.menuOpen } },
        [_vm._m(0)]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "hamburger-box" }, [
      _c("div", { staticClass: "hamburger-inner" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }