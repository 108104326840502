/**
 * A method to update a state variable based on the param and key set
 *
 * @param {object} currentState Vuex store state
 * @param {object} params       Any data passed to update
 */
 export function updateState(currentState, params)
 {
     Object.keys(params)
         .map(key => currentState[key] = params[key]);
 }
 /**
 * Updates the Data in a mapped form
 * @param {object} currentState Vuex store state
 * @param {object} params       Any data passed to update
 * @param {string} arrField     The field to map to
 */
const updateData = (currentState, params, arrField) => {
	Object.keys(params)
		.map(key => currentState[arrField][key] = params[key])
}
/**
 * Quick way to update the Profile State
 *
 * @param {object} currentState Vuex store state
 * @param {object} params       Any data passed to update
 */
 export function updateProfile(currentState, params)
 {
     updateData(currentState, params, 'profile')
 }
/**
 * Quick way to update the Directory State
 *
 * @param {object} currentState Vuex store state
 * @param {object} params       Any data passed to update
 */
 export function updateDirectory(currentState, params)
 {
     updateData(currentState, params, 'directory')
 }
/**
 * Used for building out the sites navigation
 * @param currentState
 * @param data
 * @constructor
 */
export function buildNavigation (currentState, {viewport, data}) {
    if (viewport === 'desktop') currentState.desktopNav = data;
    if (viewport === 'mobile') currentState.mobileNav = data;
}
/**
 * Sets up a query for the state and the URLSearchParams
 *
 * @param {object} currentState Vuex store state
 * @param {object} params       Any data passed to update
 */
 export function setQuery(currentState, params)
 {
     delete params.featId;
     let searchParameters = new URLSearchParams();

     if (Object.keys(params).length === 0) currentState.queryParams = {};
     else {
         for (const [key, value] of Object.entries(params)) {
             if (value || value.length) {
                 searchParameters.set(key, value.toString());
                 currentState.queryParams[key] = value;
             }
         }
     }
     
     currentState.searchParams = searchParameters;
 }
/**
 * Build the Vuex query based on the URL params
 *
 * @param {object} currentState Vuex store state
 */
 export function buildQuery(currentState)
 {
    
     const stateQuery = {};
     const queryParams = new URL(window.location).searchParams;
     queryParams.forEach((value, key) => {
         if (key === 'categories') stateQuery[key] = value.split(',')
         else if (key === 'search' || key === 's') currentState.searchText = value;
         else if (key === 'page_num') currentState.page = Number(value);
         else if (key === 'business') currentState.directory.userBusiness = value;
         else if (key === 'program') currentState.directory.userProgram = value;
         else if (key === 'skip') currentState.directory.skipTo = value;
         else if (key === 'industry') currentState.directory.userIndustry = value;
         else if (key === 'orderby') currentState.directory.orderBy = value;
         else stateQuery[key] = value
     })

     currentState.queryParams = stateQuery
 }
 