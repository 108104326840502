var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      class: `header__item ${_vm.listItemClass(
        _vm.postId,
        _vm.menuItem.objId,
        _vm.menuItem.classes
      )}`,
    },
    [
      _c("a", {
        class: `${_vm.isActive(_vm.postId, _vm.menuItem.objId)}`,
        attrs: { href: _vm.menuItem.permalink, target: _vm.menuItem.target },
        domProps: { textContent: _vm._s(_vm.menuItem.title) },
      }),
      _vm._v(" "),
      _vm.objHasKey(_vm.menuItem, "children") && _vm.menuItem.children.length
        ? _c(
            "ul",
            { staticClass: "submenu" },
            _vm._l(
              _vm.menuItem.children,
              function ({ classes, id, objId, permalink, target, title }) {
                return _c(
                  "li",
                  {
                    key: id,
                    class: `header__item ${_vm.listItemClass(
                      _vm.postId,
                      objId,
                      classes
                    )}`,
                  },
                  [
                    _c("a", {
                      class: `${_vm.isActive(_vm.postId, objId)}`,
                      attrs: { href: permalink, target: target },
                      domProps: { textContent: _vm._s(title) },
                    }),
                  ]
                )
              }
            ),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }