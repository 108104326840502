<template>
  <div>
    <section id="banner" class="banner">
      <div class="wrapper">
        <a
          :href="directoryBtn.url"
          class="btn icon"
          v-if="!isObjEmpty(directoryBtn)"
        >
          <span class="inner">
            <span class="arrow flipped"><svg
                            class="icon-arrow"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.167 10h11.667M10 4.167 15.833 10 10 15.833"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            /></svg></span>
            <span class="text" v-html="directoryBtn.title" />
          </span>
        </a>
        <UserBanner
          v-if="users.length"
          v-for="{
            headshot,
            full_name,
            id,
            job_title,
            company_name,
            programs,
          } in users"
          :key="id"
          :fullName="full_name"
          :image="headshot"
          :jobTitle="job_title"
          :companyName="company_name"
          :programs="programs"
        />
        <a id="profile-edit-button" class="btn icon" v-if="!isObjEmpty(editBtn) || editBtn.length" :href="editBtn.url">
          <span class="inner">
            <span class="text" v-html="editBtn.title" />
            <span class="arrow" v-if="iconGear">
              <img class="icon-gear" :src="iconGear" alt="">
            </span>
          </span>
        </a>
      </div>
    </section>
    <section id="details" class="details">
        <UserDetails
          v-if="users.length"
          v-for="{
            preferre_name,
            linkedin_url,
            email,
            mobile_phone,
            industry,
            business_phone,
            primary_business_function,
            board_participation,
            private_boards,
            not_for_profit_boards,
            corporate_boards
          } in users"
          :key="email"
          :preferredName="preferre_name"
          :linkedinUrl="linkedin_url"
          :email="email"
          :industry="industry"
          :mobilePhone="mobile_phone"
          :businessPhone="business_phone"
          :primaryBusiness="primary_business_function"
          :boardParticipation="board_participation"
          :corporateBoard="corporate_boards"
          :privateBoard="private_boards"
          :nonprofitBoard="not_for_profit_boards"
        />
        <NoResults v-if="!users.length"
                   container-class="no-posts"
                   paragraph-class="result-item no-posts__text"
                   :no-results-text="userNotFound"/>
    </section>
  </div>
</template>
<script type="application/javascript">
/**
 * Vue Scripts
 */

import mixins from '../../Util/mixins';
/**
 * Vue Components
 */
import UserBanner from './UserBanner';
import UserDetails from './UserDetails';
import NoResults from "../../Components/Blocks/NoResults";
export default {
  props: {},
  data: () => ({}),
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {},
  components: { UserBanner, UserDetails,NoResults },
  computed: {
    editBtn(){
    return this.$store.state.userProfile?.editBtn;
  },
  iconGear(){
    return this.$store.state.icons?.iconGear;
  },
    userId() {
      return this.$store.state.profile.userId;
    },
    userNotFound() {return this.$store.state.userProfile?.userNotFound},
    // users() {return this.$store.state.users},
    users() {
      return this.$store.state.userProfile.userDetails.users;
    },

    directoryBtn() {
      return this.$store.state.userProfile.directoryBtn;
    },
  },
  watch: {},
  mixins: [mixins],
  name: 'userContainer',
};
</script>
