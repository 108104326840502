<template>
  <div class="wrapper">
    <label
      class="subhead-xs bold mbl-lptp-only"
      for="search"
      v-html="searchPlaceholder"
    />

    <div class="combo-wrap sort-by">
      <label class="subhead-xs bold" for="sort-by" v-html="sortLabel" />
      <input
        type="text"
        class="combobox"
        :value="selectedSort"
        id="sort-by"
        name="sort-by"
        inputmode="none"
        placeholder="Select"
        @select="orderByUser"
      />
      <div class="icon-caret">
        <svg
          class="caret"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m1 1 4 4 4-4" />
        </svg>
      </div>
      <div class="listbox select-sort">
        <div class="option title-md" data-value="lastasc">Last Name A - Z</div>
        <div class="option title-md" data-value="lastdesc">Last Name Z - A</div>
        <div class="option title-md" data-value="firstasc">
          First Name A - Z
        </div>
        <div class="option title-md" data-value="firstdesc">
          First Name Z - A
        </div>
      </div>
    </div>
    <div class="search-wrap">
      <div class="search-input-wrap">
        <span class="icon icon-search">
          <svg
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.5 9.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM10.5 10.5 8.325 8.325"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <input
          type="text"
          class="search"
          id="search"
          name="search"
          v-model="searchForText"
        />
        <span class="placeholder-text" v-html="searchPlaceholder" />
      </div>
      <button
        class="btn trigger-search-btn"
        @click.prevent="fetchUsers({ page: 1, searchText: searchForText || '' })"
        @keydown.enter.prevent="fetchUsers({ page: 1, searchText: searchForText || '' }) "
        v-text="searchButton"
      />
    </div>

    <div class="combo-wrap mbl-lptp-only">
      <label class="subhead-xs bold" for="skip-to">Skip to</label>
      <input
        type="text"
        class="combobox"
        id="skip-to"
        :value="selectedSkipTo"
        name="skip-to"
        inputmode="none"
        placeholder="Select"
      />
      <div class="icon-caret">
        <svg
          class="caret"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m1 1 4 4 4-4" />
        </svg>
      </div>
      <div class="listbox select-skip">
        <div
          class="option title-md"
          v-for="alpha in letters"
          :key="alpha"
          :letter="alpha"
        >
          Last Name {{ alpha }}
        </div>
      </div>
    </div>
    <div class="skip-to lptp-lg-only">
      <p class="subhead-xs">Skip To</p>
      <ul class="alphabet-list no-bullets">
        <li v-for="alpha in alphabets" :key="alpha">
          <a href="#" :letter="alpha">{{ alpha }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script type="application/javascript">
/**
 * Vue Scripts
 */
import mixins from '../../Util/mixins';
/**
 * Vue Components
 */

export default {
  props: {},
  data: () => ({
    searchForText: '',
    order: '',
    skipAlfas: [],
    listAlfas: [],
    alphabets: [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ],
  }),
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.skipAlfas = document.querySelectorAll('.select-skip .option');
    [...this.skipAlfas].map((elm) =>
      elm.addEventListener('click', () => {
        this.skipTo = elm.getAttribute('letter');
        this.filterSkipLetter();
      })
    );
    this.listAlfas = document.querySelectorAll('.alphabet-list li a');
    [...this.listAlfas].map((elm) =>
      elm.addEventListener('click', (evt) => {
        this.skipTo = elm.getAttribute('letter');
        evt.preventDefault()
        this.filterSkipLetter();
      })
    );
  },
  methods: {
    orderByUser(e) {
      this.orderBy = e.target.value;
      this.$store.commit('updateDirectory', { orderBy: this.orderBy });
      this.$store.dispatch('getUsers', { update: true });
    },
    skiptoLetter(e) {
      this.skipTo = e.target.value;
      this.filterSkipLetter();
    },
    filterSkipLetter() {
      this.$store.commit('updateDirectory', { skipTo: this.skipTo });
      this.$store.commit('updateState', {page: 1})
      this.$store.dispatch('getUsers', {update: true });
    },
  },
  components: {},
  computed: {
    searchText() {
      return this.$store.state.searchText;
    },
    letters() {
      return this.$store.state.directoryDefault.letters;
    },
    searchPlaceholder() {
      return this.$store.state.directoryDefault.searchPlaceholder;
    },
    sortLabel() {
      return this.$store.state.directoryDefault.sortLabel;
    },
    searchButton() {
      return this.$store.state.directoryDefault.searchButton;
    },
    skipTitle() {
      return this.$store.state.directoryDefault.skipTitle;
    },
    selectedSort() {
      return this.$store.state.directory?.orderBy;
    },
    selectedSkipTo() {
      return this.$store.state.directory?.skipTo;
    },
  },
  watch: {
    searchText(newValue, oldValue) {
      if (newValue && newValue !== oldValue) this.searchForText = newValue;
    },
  },
  mixins: [mixins],
  name: 'userSearch',
};
</script>
