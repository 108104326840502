var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "user-results" } },
    [
      _c("Loading", { attrs: { "is-loading": _vm.loading } }),
      _vm._v(" "),
      _vm._l(
        _vm.users,
        function ({
          headshot,
          first_name,
          last_name,
          id,
          job_title,
          company_name,
          industry,
          primary_business_function,
          programs,
          permalink,
        }) {
          return _vm.users.length && !_vm.loading
            ? _c("UserItem", {
                key: id,
                attrs: {
                  permalink: permalink,
                  image: headshot,
                  first_name: first_name,
                  last_name: last_name,
                  jobTitle: job_title,
                  industry: industry,
                  company_name: company_name,
                  primaryBusiness: primary_business_function,
                  programs: programs,
                },
              })
            : _vm._e()
        }
      ),
      _vm._v(" "),
      !_vm.users.length && !_vm.loading
        ? _c("NoResults", {
            attrs: {
              "container-class": "no-posts",
              "paragraph-class": "result-item no-posts__text",
              "no-results-text": _vm.noResults,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }