import axios from "axios";

const windowUrl = new URL(window.location);
/**
 * Makes an API call and setups data to store state,
 * as well as whether to update the browser history
 *
 * @param {string} api The API Url to make a call to
 * @param {object} store The vuex store state
 * @param {object} config Any config content to send to
 * @param {int} page The page number
 * @param {boolean} updateHistory Whether or not to update the browser history
 * @param {boolean} pushData Whether to add data to the var or replace it
 *
 * @returns {Promise<unknown>}
 */
 const makeApiCall = (api, store, config = {params: {}}, page = 1, updateHistory = false, pushData = false) => {
    updateLoading(store, true);
    let commitType = "updateState";
    if (pushData) commitType = "mergeData";
    return axios
        .get(api, config)
        .then(({data, status}) => {
            const {maxPages, posts, postTypeCount} = data;
            (status === 200 && data.status !== 404) && store.commit(commitType, {maxPages: Number(maxPages), page, posts});
            postTypeCount !== undefined && store.commit('searchPostTypeCount', {postTypeCount});
        })
        .catch(err => console.error(err))
        .finally(() => {
            // Remove the Page Number from the query params if on the first page
            if (page > 1) config.params.page_num = page;
            else delete config.params.page_num;

            // Remove the updateCount from the query params
            delete config.params.updateCount;

            store.commit("setQuery", config.params);
            if (updateHistory) store.dispatch("updateBrowserHistory", {updateHistory});
            updateLoading(store);
        });
};
/**
 * Makes an API call and setups data to store state,
 * as well as whether to update the browser history
 *
 * @param {string} api The API Url to make a call to
 * @param {object} store The vuex store state
 * @param {object} config Any config content to send to
 * @param {int} page The page number
 * @param {boolean} updateHistory Whether or not to update the browser history
 * @param {boolean} pushData Whether to add data to the var or replace it
 *
 * @returns {Promise<unknown>}
 */
 const makeUserApiCall = (api, store, config = {params: {}}, page = 1, updateHistory = false, pushData = false) => {
    updateLoading(store, true);
    let commitType = "updateState";
    if (pushData) commitType = "mergeData";
    return axios
        .get(api, config)
        .then(({data, status}) => {
            const {maxPages, users, postTypeCount} = data;
            (status === 200 && data.status !== 404) && store.commit(commitType, {maxPages: Number(maxPages), page, users});
            postTypeCount !== undefined && store.commit('searchPostTypeCount', {postTypeCount});
            })
        .catch(err => console.error(err))
        .finally(() => {
            // Remove the Page Number from the query params if on the first page
            if (page > 1) config.params.page_num = page;
            else delete config.params.page_num;

            // Remove the updateCount from the query params
            delete config.params.updateCount;
            store.commit("setQuery", config.params);
            if (updateHistory) store.dispatch("updateBrowserHistory", {updateHistory});
            updateLoading(store);
        });
};

/**
 * Makes an update to the store state on the `loading` condition
 *
 * @param {object} store    The Vuex store state
 * @param {boolean} loading Whether the loading animation should appear or not
 * @returns {*}
 */
const updateLoading = (store, loading = false) => store.commit("updateState", {loading})

/**
 * API call to grab the navigation items from the Rest API
 * @param store
 * @param opts
 * @returns {Promise<AxiosResponse<any> | void>}
 */
export function getNavigation (store, opts) {
    const {viewport, navLocation} = opts;
    return axios
        .get(
            NAV.api,
            {params: {navLocation, viewport}}
        )
        .then(({data, status}) => {
            if (status === 200 && data.status !== 404) {
                store.commit("buildNavigation", {
                    viewport: viewport,
                    data: data || null,
                });
            }
        })
        .catch(err => console.error(err));
}

/**
 * Method to make an API call to get the content for the Search Page
 *
 * @param {object} store
 * @param {object} params Any parameters for the API call
 * @returns {Promise<AxiosResponse<any> | void>}
 */
 export function getUsers(store, params = {update: false})
 {

     const {update, updateCount} = params;
     let {apiUrls, page, directory, users, searchText} = store.state;
     const api = apiUrls.directory
     const config = {
         params: {},
         headers: {
             'X-WP-Nonce': wpApiSettings.nonce
         }
     };
     // Setup Config
     let {orderBy,skipTo,userBusiness,userProgram,userIndustry} = directory;
     if (searchText) config.params.search = searchText;
     if (orderBy) config.params.orderby = orderBy;
     if (skipTo) config.params.skip = skipTo;
     if (userBusiness) config.params.business = userBusiness;
     if (userProgram) config.params.program = userProgram;
     if (userIndustry) config.params.industry = userIndustry;

     return makeUserApiCall(
         api + (page || 1),
         store,
         config,
         (page || 1),
         update,
     );
 }
/**
 * Method to make an API call to get the content for the Profile Page
 *
 * @param {object} store
 * @param {object} params Any parameters for the API call
 * @returns {Promise<AxiosResponse<any> | void>}
 */
 export function getUserProfile(store, params = {update: false})
 {

     const {update, updateCount} = params;
     let {apiUrls, profile} = store.state;
     const api = apiUrls.userProfile
     let {userId} = profile;
     const config = {params: {}};
     // Setup Config
     if (userId) config.params.user_id = userId;

     return makeUserApiCall(
         api + (userId||0),
         store,
         config,
         (userId||0),
         update,
     );
 }
 /**
 * Method to build and send to the browser's history any
 * query parameters built on the page when the user filters
 * any content
 *
 * @param {object} store The Vuex store state
 * @param {boolean} updateHistory Whether to update the history or not
 */
export function updateBrowserHistory(store, updateHistory = false)
{
    const {searchParams} = store.state;
    let newUrl = "";
    if (searchParams.toString() && updateHistory) newUrl = `?${searchParams.toString()}`;

    const urlLocation = windowUrl.origin + windowUrl.pathname + newUrl;
    window.history.pushState({path: urlLocation}, document.title, urlLocation);
}
