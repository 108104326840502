var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.desktopNav && _vm.mobileNav
    ? _c(
        "header",
        { class: _vm.headerClass },
        [
          _c("MobileHamburger", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  (_vm.$resize && _vm.viewport === "mobile") ||
                  _vm.viewport === "mobile",
                expression:
                  "($resize && viewport === 'mobile') || viewport === 'mobile'",
              },
            ],
            attrs: { homeUrl: _vm.homeUrl, "menu-open": _vm.mobileMenuOpen },
          }),
          _vm._v(" "),
          (_vm.$resize && _vm.viewport === "desktop") ||
          _vm.viewport === "desktop"
            ? _c("DesktopNavigation", {
                attrs: {
                  homeUrl: _vm.homeUrl,
                  menu: _vm.desktopNav,
                  "post-id": _vm.postId,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          (_vm.$resize && _vm.viewport === "mobile") ||
          _vm.viewport === "mobile"
            ? _c("MobileNavigation", {
                attrs: {
                  homeUrl: _vm.homeUrl,
                  "menu-open": _vm.mobileMenuOpen,
                  menu: _vm.mobileNav,
                  "post-id": _vm.postId,
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }