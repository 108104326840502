var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "banner", attrs: { id: "banner" } }, [
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          !_vm.isObjEmpty(_vm.directoryBtn)
            ? _c(
                "a",
                {
                  staticClass: "btn icon",
                  attrs: { href: _vm.directoryBtn.url },
                },
                [
                  _c("span", { staticClass: "inner" }, [
                    _c("span", { staticClass: "arrow flipped" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "icon-arrow",
                          attrs: {
                            viewBox: "0 0 20 20",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M4.167 10h11.667M10 4.167 15.833 10 10 15.833",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "text",
                      domProps: { innerHTML: _vm._s(_vm.directoryBtn.title) },
                    }),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(
            _vm.users,
            function ({
              headshot,
              full_name,
              id,
              job_title,
              company_name,
              programs,
            }) {
              return _vm.users.length
                ? _c("UserBanner", {
                    key: id,
                    attrs: {
                      fullName: full_name,
                      image: headshot,
                      jobTitle: job_title,
                      companyName: company_name,
                      programs: programs,
                    },
                  })
                : _vm._e()
            }
          ),
          _vm._v(" "),
          !_vm.isObjEmpty(_vm.editBtn) || _vm.editBtn.length
            ? _c(
                "a",
                {
                  staticClass: "btn icon",
                  attrs: { id: "profile-edit-button", href: _vm.editBtn.url },
                },
                [
                  _c("span", { staticClass: "inner" }, [
                    _c("span", {
                      staticClass: "text",
                      domProps: { innerHTML: _vm._s(_vm.editBtn.title) },
                    }),
                    _vm._v(" "),
                    _vm.iconGear
                      ? _c("span", { staticClass: "arrow" }, [
                          _c("img", {
                            staticClass: "icon-gear",
                            attrs: { src: _vm.iconGear, alt: "" },
                          }),
                        ])
                      : _vm._e(),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "details", attrs: { id: "details" } },
      [
        _vm._l(
          _vm.users,
          function ({
            preferre_name,
            linkedin_url,
            email,
            mobile_phone,
            industry,
            business_phone,
            primary_business_function,
            board_participation,
            private_boards,
            not_for_profit_boards,
            corporate_boards,
          }) {
            return _vm.users.length
              ? _c("UserDetails", {
                  key: email,
                  attrs: {
                    preferredName: preferre_name,
                    linkedinUrl: linkedin_url,
                    email: email,
                    industry: industry,
                    mobilePhone: mobile_phone,
                    businessPhone: business_phone,
                    primaryBusiness: primary_business_function,
                    boardParticipation: board_participation,
                    corporateBoard: corporate_boards,
                    privateBoard: private_boards,
                    nonprofitBoard: not_for_profit_boards,
                  },
                })
              : _vm._e()
          }
        ),
        _vm._v(" "),
        !_vm.users.length
          ? _c("NoResults", {
              attrs: {
                "container-class": "no-posts",
                "paragraph-class": "result-item no-posts__text",
                "no-results-text": _vm.userNotFound,
              },
            })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }